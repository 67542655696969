import { STUDY_PLACEHOLDER } from '@modules/modelEditor/modals/components';
import ace from 'ace-builds/src-noconflict/ace';

export class CustomSqlHighlightRules extends ace.acequire('ace/mode/sql_highlight_rules').SqlHighlightRules {
  constructor() {
    super();

    const sqlRules = this.$rules.start;

    this.$rules = {
      start: [
        {
          token: 'customStudyPlaceholder',
          regex: `${STUDY_PLACEHOLDER}`,
        },
        ...sqlRules,
      ],
    };
  }
}

export default class CustomSqlHighlightMode extends ace.acequire('ace/mode/sql').Mode {
  constructor() {
    super();
    this.HighlightRules = CustomSqlHighlightRules;
  }
}
