import { DraggableModal, Form, FormItem, FormLayout } from '@ui';
import { ModelEditorNodeDomain } from '@modules/modelEditor/ModelEditorTypes';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { useStoreAggregation } from '@shared/components/TableListSider/tableListSiderHooks';

import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { TreeSelect } from 'antd';
import { useSaveStage } from './modelEditorModalsHooks';

const ModelEditorModalsDomainSettingsContent = ({ data, onClose, t }: ModelEditorModalsDomainSettingsContentProps) => {
  const [form] = Form.useForm();
  const readOnly = useSelector(selectModelEditorReadOnly);
  const { onSubmit } = useSaveStage(data.nodeId, onClose);

  const { treeData } = useStoreAggregation();

  const tablesOptions = useMemo(
    () =>
      treeData
        ? treeData.map((el) => ({
            title: el.name,
            value: el.id,
            selectable: false,
            children: el.children?.map((item) => ({ title: item.name, value: item.metadata?.originalId })) ?? [],
          }))
        : [],
    [treeData],
  );

  const initValues = data.initData || {};

  const onSave = (values: Parameters<typeof onSubmit>[0]) => {
    const updatedValues = {
      ...values,
      name: (values as ModelEditorNodeDomain)?.tableName?.split('.')[1] ?? '',
    };
    onSubmit(updatedValues);
  };

  return (
    <FormLayout
      form={form}
      readOnly={readOnly}
      layout="horizontal"
      onCancel={onClose}
      onSubmit={onSave}
      okText={t('save')}
      initialValues={initValues}
    >
      <FormItem name="tableName" label={t('domain.table')} rules={[{ required: true }]}>
        <TreeSelect treeData={tablesOptions} placeholder={t('domain.tablePlaceholder')} showSearch allowClear />
      </FormItem>
    </FormLayout>
  );
};

export const ModelEditorModalsDomainSettings = ({ open, data, onClose }: ModelEditorModalsDomainSettingsProps) => {
  const { t } = useTranslation(['model']);

  return (
    <DraggableModal open={open} onCancel={onClose} title={t('domain.title')} footer={null} destroyOnClose>
      {open && <ModelEditorModalsDomainSettingsContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

interface ModelEditorModalsDomainSettingsContentProps
  extends Pick<ModelEditorModalsDomainSettingsProps, 'data' | 'onClose'> {
  t: TFunction;
}

export interface ModelEditorModalsDomainSettingsProps {
  open: boolean;
  data: { nodeId: string; initData?: ModelEditorNodeDomain };
  onClose: () => void;
}
