import { Model } from '@modules/model/ModelTypes';
import {
  Alert,
  Button,
  DraggableModal,
  Form,
  FormItem,
  FormLayout,
  Input,
  notification,
  Popconfirm,
  Select,
  Space,
  Switch,
  Tabs,
  TextArea,
} from '@ui';
import { useDeleteModelMutation, useSaveModelMutation } from '@modules/model/duck/modelApi';
import { SchedulerRunFormValues } from '@components/form/schedulerRun/schedulerRunFormTypes';
import {
  getDefaultInitialValue,
  getDefaultInitialValueGpdip,
  normalizeUsers,
  NotificationsForm,
  prepareFields,
  prepareFieldsGpdip,
  prepareUpdateData,
  prepareUpdateDataExport,
  SchedulerRunForm,
  SchedulerRunFormValuesGpdip,
  SearchUsersQuery,
  SearchUsersResponse,
  TNotificationConfig,
} from '@components/form';
import { useEnvironments } from '@modules/viewer/duck/viewerHooks';
import { selectSelectedModelEnv } from '@modules/model/duck/modelSelectors';
import { GpdipForm } from '@components/form/gpdipRun/GpdipForm';
import { renameModelNameInSchema } from '@modules/modelEditor/duck/modelEditorUtils';
import { useManualUploadStores } from '@modules/stores/duck/storeHooks';
import { QueryErrorType } from '@shared/utils/Error';
import { selectStudyFallbackCHDB } from '@modules/study/duck/studySelectors';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import i18n from '@i18n';
import { useLazySearchUsersQuery } from '@modules/user/duck/userApi';
import { DataStageTablePrefix } from '@config/constants';
import { useFeatures, useStudyPermissions } from '@modules/user/duck/userHooks';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const tabs = {
  scheduler: {
    id: 'scheduler',
    label: i18n.t('model.saveForm.scheduler'),
  },
  notifications: {
    id: 'notifications',
    label: i18n.t('model.saveForm.notifications'),
  },
  gpdip: {
    id: 'gpdip',
    label: i18n.t('model.saveForm.gpdip'),
  },
};

const DataModelModalsSaveModalContent = ({ data, onClose, t, isEdit }: ModelModalsSaveModalContentProps) => {
  const [searchUsersQuery] = useLazySearchUsersQuery();
  const [form] = Form.useForm();
  const fallbackCHDB = useSelector(selectStudyFallbackCHDB);
  const { userCurrentEnv } = useEnvironments(true);
  const modelEnv = useSelector(selectSelectedModelEnv) || userCurrentEnv;
  const {
    userPermissions: { canDataModelDelete, canDataModelUpdate, canGpdipConfigure },
  } = useStudyPermissions();
  const { gpdip } = useFeatures();
  const globalStudy = useSelector(selectGlobalStudy);
  const user = useSelector(selectAuthUser);
  const [deleteModel, deleteModelQuery] = useDeleteModelMutation();
  const [saveModel, saveModeQuery] = useSaveModelMutation();
  const globalStudyId = globalStudy?.id!;
  const [alertMessage, setAlertMessage] = useState(form.getFieldValue(''));
  const { loading, manualUploadStores } = useManualUploadStores(
    data?.data_store?.id,
    { onlyUserStores: true },
    globalStudyId,
  );

  const deleteLoading = deleteModelQuery.isLoading;
  const isCurrentModelEnv = userCurrentEnv === modelEnv;

  const searchUsers = useCallback(
    async (params: SearchUsersQuery): Promise<SearchUsersResponse> => await searchUsersQuery(params).unwrap(),
    [searchUsersQuery],
  );

  useEffect(() => {
    if (data) {
      onActiveChange(form.getFieldValue('active'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, data]);

  useEffect(() => {
    if (!loading && !manualUploadStores.length) {
      form.setFields([{ name: 'data_store_id', errors: [t('shared.errors.emptyManualUploadStoreList')] }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualUploadStores, loading]);

  const onSubmit = async (values: ModelSaveFieldFormValues) => {
    try {
      const notificationData = values.notification || data?.notification || initValues.notification;
      const updatedFields = {
        ...values,
        name: `${DataStageTablePrefix}${values['name']}`,
        id: data?.id,
        intermediate: false,
        job: values.job ? prepareUpdateData({ values: values.job }) : data.job!,
        export: prepareUpdateDataExport({
          values: values.export || (data?.export ? prepareFieldsGpdip(data?.export!) : getDefaultInitialValueGpdip()),
        }),
        notification: {
          ...notificationData,
          send_email: false, // TODO remove when application could send emails
          users: normalizeUsers(notificationData.users),
        },
      };

      if (isEdit && updatedFields.name !== data.name && data.schema) {
        await saveModel({
          ...updatedFields,
          ...renameModelNameInSchema(data.schema, updatedFields.name, fallbackCHDB, user!.env_name),
        }).unwrap();
      } else {
        await saveModel(updatedFields).unwrap();
      }

      onClose();
      notification.success({
        message: t(isEdit ? 'notification.form.successMessageEdit' : 'notification.form.successMessageCreate', {
          name: values.name,
        }),
      });
    } catch (e) {
      notification.error({
        message: t(isEdit ? 'notification.form.errorMessageEdit' : 'notification.form.errorMessageCreate', {
          name: values.name,
        }),
      });
    }
  };

  const onDeleteModel = async () => {
    deleteModel(data.id!)
      .then(() => {
        onClose();
        notification.success({ message: t('notification.form.successMessageDelete', { name: data.name }) });
      })
      .catch(() =>
        notification.error({
          message: t('notification.form.errorMessageDelete', { name: data.name }),
        }),
      );
  };

  const onActiveChange = (checked: boolean) => {
    return checked
      ? setAlertMessage('')
      : setAlertMessage(gpdip ? t('saveForm.alertNoActive') : t('saveForm.alertNoActiveWithoutGPDIP'));
  };

  const initValues = (isEdit &&
    data && {
      name: (data.name || '').replace(new RegExp(`^${DataStageTablePrefix}`), ''),
      description: data.description,
      data_store_id: data.data_store?.id,
      active: data.active,
      job: data.job ? prepareFields(data.job!) : getDefaultInitialValue(),
      export: data.export ? prepareFieldsGpdip(data.export!) : getDefaultInitialValueGpdip(),
      notification: data.notification,
      intermediate: false,
    }) || {
    data_store_id: manualUploadStores[0]?.id,
    intermediate: false,
    active: true,
    job: getDefaultInitialValue(),
    export: getDefaultInitialValueGpdip(),
    notification: {
      send_email: false,
      users: [],
    },
  };

  const isDisabledForm = isEdit && (!canDataModelUpdate || !isCurrentModelEnv);

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      disabled={isDisabledForm}
      hideOkBtn={isDisabledForm}
      submitIsDisabled={deleteLoading}
      cancelIsDisabled={deleteLoading}
      extraActions={
        isEdit &&
        canDataModelDelete && (
          <Space>
            <Popconfirm
              title={t('confirmation.title')}
              description={t('confirmation.description')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={onDeleteModel}
            >
              <Button danger loading={deleteLoading} disabled={saveModeQuery.isLoading}>
                {t('delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      }
      errors={saveModeQuery.error ? [(saveModeQuery.error as QueryErrorType).data.userMsg!] : undefined}
    >
      <FormItem
        name="name"
        label={t('saveForm.name')}
        rules={[
          { required: true },
          {
            pattern: /^[A-Za-z0-9_]+$/,
            message: t('saveForm.validationMessage'),
          },
        ]}
      >
        <Input addonBefore={DataStageTablePrefix} />
      </FormItem>
      <FormItem name="description" label={t('saveForm.description')} rules={[{ required: true }]}>
        <TextArea />
      </FormItem>
      <FormItem name="data_store_id" label={t('saveForm.dataStore')} rules={[{ required: true }]}>
        <Select
          options={manualUploadStores}
          placeholder={t('select')}
          loading={loading}
          fieldNames={{ label: 'name', value: 'id' }}
        />
      </FormItem>
      <FormItem name={['active']} label={t('saveForm.status')} valuePropName="checked">
        <Switch onClick={onActiveChange} />
      </FormItem>
      <FormItem wrapperCol={{ span: 24 }}>
        <Tabs
          defaultActiveKey={tabs.scheduler.id}
          type="card"
          size="small"
          items={
            [
              {
                key: tabs.scheduler.id,
                label: tabs.scheduler.label,
                children: <SchedulerRunForm isDisabledProp={isDisabledForm} />,
              },
              {
                key: tabs.notifications.id,
                label: tabs.notifications.label,
                children: <NotificationsForm form={form} data={data?.notification} search={searchUsers} />,
              },
              gpdip &&
                canGpdipConfigure && {
                  key: tabs.gpdip.id,
                  label: tabs.gpdip.label,
                  children: <GpdipForm />,
                },
            ].filter((item) => typeof item !== 'boolean') as Array<{ key: string; label: string; children: ReactNode }>
          }
        />
      </FormItem>
      {alertMessage && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert type="warning" message={alertMessage} />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const DataModelModalsSaveModal = ({ open, data, onClose }: ModelModalsSaveModalProps) => {
  const { t } = useTranslation(['model', 'gpdip']);
  const isEdit = !!(data as Model)?.id;

  return (
    <DraggableModal
      open={open}
      onCancel={onClose}
      title={isEdit ? t('saveForm.titleEdit') : t('saveForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <DataModelModalsSaveModalContent data={data} onClose={onClose} t={t} isEdit={isEdit} />}
    </DraggableModal>
  );
};

export interface ModelModalsSaveModalProps {
  open: boolean;
  data: Partial<Model>;
  onClose: () => void;
}

interface ModelModalsSaveModalContentProps extends Pick<ModelModalsSaveModalProps, 'data' | 'onClose'> {
  t: TFunction;
  isEdit: boolean;
}

interface ModelSaveFieldFormValues {
  name: string;
  description: string;
  data_store_id: number;
  intermediate: boolean;
  job: SchedulerRunFormValues;
  export: SchedulerRunFormValuesGpdip;
  notification: TNotificationConfig;
}
