import { TFunction } from 'i18next';
import { AuditLogItemAction, AuditLogItemKind } from '../AuditTypes';

export const getActionTitle = (t: TFunction, action: AuditLogItemAction) => {
  switch (action) {
    case 'CREATED':
      return t('audit.table.created');
    case 'UPDATED':
      return t('audit.table.updated');
    case 'DELETED':
      return t('audit.table.deleted');
    case 'IMPORTED':
      return t('audit.table.imported');
    default:
      return action;
  }
};

export const getKindTitle = (t: TFunction, kind?: AuditLogItemKind) => {
  switch (kind) {
    case 'MODEL':
      return t('audit.table.model');
    case 'STORE':
      return t('audit.table.store');
    case 'SOURCE':
      return t('audit.table.source');
    case 'FILE':
      return t('audit.table.file');
    case 'TABLE':
      return t('audit.table.table');
    case 'DATASETS':
      return t('audit.table.datasets');
    default:
      return kind;
  }
};
